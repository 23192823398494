import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Modules
import { CometchatModule } from './cometchat/cometchat.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ScrollingModule } from '@angular/cdk/scrolling';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    CometchatModule,
    HttpClientModule,
    DeviceDetectorModule,
    ScrollingModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
