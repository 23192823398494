export const environment = {
  production: false,
  api: {
    url: 'https://vivagym.myvitale.com/api',
    version: '/v2.0',
  },
  cometChat: {
    appId: '3230321efe9dc79',
    apiKey: 'c3c1e81982aa9cb5efe92ddf2129878bd27888a3',
    region: 'eu',
  },
};
